import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Field } from "formik";
import reduce from "lodash/reduce";
import cn from "classname";

import data from "./data";

const initialValues = {
  d_sun: "",
  d_earth: "",
  d_moon: "",
  d_north: "",
  d_south: "",
  d_mercury: "",
  d_venus: "",
  d_mars: "",
  d_jupiter: "",
  d_saturn: "",
  d_uranus: "",
  d_neptune: "",
  d_pluto: "",
  p_sun: "",
  p_earth: "",
  p_moon: "",
  p_north: "",
  p_south: "",
  p_mercury: "",
  p_venus: "",
  p_mars: "",
  p_jupiter: "",
  p_saturn: "",
  p_uranus: "",
  p_neptune: "",
  p_pluto: "",
};

const PLANET_LABELS = [
  {
    key: "sun",
    symbol: "☉︎",
    label: "太陽",
    meaning: "動態生命能量、身份認同、生命目的。",
  },
  {
    key: "earth",
    symbol: "🜨",
    label: "地球",
    meaning: "立基之地、接受性、身體。",
  },
  {
    key: "moon",
    symbol: "☽︎",
    label: "月亮",
    meaning: "反思、感覺記憶、過去。",
  },
  {
    key: "north",
    symbol: "☊",
    label: "北交點",
    meaning: "你的新方向與當前的生命目的。",
  },
  {
    key: "south",
    symbol: "☋",
    label: "南交點",
    meaning: "你過去的人生方向與要學習的課題。",
  },
  {
    key: "mercury",
    symbol: "☿",
    label: " 水星",
    meaning: "與環境的連結、溝通。",
  },
  {
    key: "venus",
    symbol: "♀",
    label: "金星",
    meaning: "愛、美、撫慰、關係、陰柔特質、道德。",
  },
  {
    key: "mars",
    symbol: "♂",
    label: "火星",
    meaning: "活動、戰爭、侵略、進展、生存的動力、性。",
  },
  {
    key: "jupiter",
    symbol: "♃",
    label: " 木星",
    meaning: "擴張、更高的學習、哲學本質。",
  },
  {
    key: "saturn",
    symbol: "♄",
    label: "土星",
    meaning: "生命課題、架構、因/果、陰暗面。",
  },
  {
    key: "uranus",
    symbol: "♅",
    label: "天王星",
    meaning: "覺醒、劇烈的改變、更高的意識。",
  },
  {
    key: "neptune",
    symbol: "♆",
    label: "海王星",
    meaning: "條件的愛/困惑/妄想、神祕層次。",
  },
  {
    key: "pluto",
    symbol: "♇",
    label: " 冥王星",
    meaning: "轉變、更高層次的死亡與重生。",
  },
];

function validateInput(value) {
  let error;

  if (value.toString().split(".").length === 2) {
    const [gate, line] = value.toString().split(".");
    if (!(parseInt(gate) > 0 && parseInt(gate) <= 64)) {
      return (error = "超出閘門範圍 1~64");
    }
    if (!(parseInt(line) > 0 && parseInt(line) <= 6)) {
      return (error = "超出爻線範圍 1~6");
    }
  } else {
    return (error = "格式錯誤 (範例: 12.3)");
  }

  return error;
}
function generateQuery(values) {
  return PLANET_LABELS.map(({ key }) => values[key]).join("|");
}

function generateResult(values) {
  return reduce(
    values,
    (acc, v, planet) => {
      const [g, l] = v.split(".");
      const gateNum = parseInt(g);
      const lineNum = parseInt(l);

      let { gate, gua, lines } = data[gateNum - 1];
      let line = lines[lineNum - 1];
      let sub = line[planet] || line.sub;

      acc[planet] = {
        gateNum,
        lineNum,
        gate,
        gua,
        main: line.main,
        sub,
        subFromPlanet: !!line[planet],
      };

      return acc;
    },
    {}
  );
}

function shareResult() {
  if (!!navigator.share) {
    (async () => {
      navigator.share({
        title: "人類圖爻辭速查",
        text: "快來看我的人類圖爻辭查詢結果",
        url: window.location.href,
      });
    })();
  } else if (!!navigator.clipboard) {
    (async () => {
      navigator.clipboard.writeText(window.location.href).then(() => {
        alert("已複製本查詢結果URL。");
      });
    })();
  }
}

function TextField({ name, values, errors, touched, ...restProps }) {
  const value = values[name];
  const error = errors[name];
  const hasTouched = touched[name];
  return (
    <div className="relative">
      <Field
        type="number"
        className={cn(
          "w-full input input-sm md:input-md input-bordered input-ghost mt-1.5",
          {
            "input-error": !!value && error && hasTouched,
          }
        )}
        name={name}
        value={values[name]}
        {...restProps}
      />
      {value && error && hasTouched && (
        <div className="absolute text-xs h-8 md:h-12 right-3 top-0 flex items-center text-red-400">
          {error}
        </div>
      )}
    </div>
  );
}

function Form({ setSearchParams }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        let [design, personality] = reduce(
          values,
          (acc, value, key) => {
            if (key.match(/d_/)) {
              acc[0][key.slice(2)] = value;
            } else if (key.match(/p_/)) {
              acc[1][key.slice(2)] = value;
            }
            return acc;
          },
          [{}, {}]
        );

        setSearchParams({
          d: generateQuery(design),
          p: generateQuery(personality),
        });
        actions.setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        handleReset,
        values,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="px-3">
            <div className="flex flex-wrap -mx-3">
              <div className="flex w-full md:w-1/2 px-2 mb-6">
                <div className="flex-1 card shadow-xl bg-red-100">
                  <div className="card-body">
                    <h2 className="card-title mb-2.5">設計 Design</h2>
                    {PLANET_LABELS.map(({ key, symbol, label }) => (
                      <TextField
                        key={`d_${key}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validate={validateInput}
                        values={values}
                        errors={errors}
                        touched={touched}
                        name={`d_${key}`}
                        placeholder={`${symbol} ${label}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 px-3 mb-6">
                <div className="flex-1 card shadow-xl bg-gray-100">
                  <div className="card-body">
                    <h2 className="card-title mb-2.5">個性 Personality</h2>
                    {PLANET_LABELS.map(({ key, symbol, label }) => (
                      <TextField
                        key={`p_${key}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validate={validateInput}
                        values={values}
                        errors={errors}
                        touched={touched}
                        name={`p_${key}`}
                        placeholder={`${symbol} ${label}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-3">
              <button type="submit" className="btn px-10 mr-6">
                送出查詢
              </button>
              <button className="btn btn-outline px-10" onClick={handleReset}>
                清除表單
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

function ResultBlock({
  label,
  symbol,
  gate,
  gua,
  gateNum,
  lineNum,
  main,
  sub,
  subFromPlanet,
}) {
  return (
    <div className="mb-2">
      <div className="text-xs md:text-sm">
        {label}：{gate}的閘門 / {gua}卦
      </div>
      <div className="font-medium text-sm md:text-base py-1 md:leading-8">
        {gateNum}.{lineNum} {main}
      </div>
      <div className="text-xs md:text-sm text-gray-500">
        {subFromPlanet ? `${symbol} ${sub}` : sub}
      </div>
    </div>
  );
}

function PlanetMeaning() {
  return (
    <>
      <input type="checkbox" id="planet-meaning" className="modal-toggle" />
      <label
        className="modal modal-bottom sm:modal-middle cursor-pointer"
        htmlFor="planet-meaning"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="font-medium px-2 mb-3">行星符號以及其能量影響</h3>
          <ul>
            {PLANET_LABELS.map(({ key, symbol, label, meaning }) => (
              <li key={key} className="flex text-sm">
                <span className="w-20 text-right">
                  {symbol} {label}：
                </span>
                <span>{meaning}</span>
              </li>
            ))}
          </ul>
          <div className="modal-action">
            <label htmlFor="planet-meaning" className="btn">
              關閉
            </label>
          </div>
        </label>
      </label>
    </>
  );
}

function HowToUse() {
  return (
    <>
      <input type="checkbox" id="how-to-use" className="modal-toggle" />
      <label
        className="modal modal-bottom sm:modal-middle cursor-pointer"
        htmlFor="how-to-use"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="font-medium mb-3">如何使用人類圖爻辭速查</h3>
          <p className="py-4 leading-6">
            將測出來的人類圖結果，左右框中的數字依序輸入到表格中。
            <br />
            <br />
            人類圖上左邊紅色框代表你的
            <span className="text-red-400 font-medium">設計</span>
            ，右邊黑色框代表你的<span className="font-medium">個性</span>
            ，行星符號由上往下依序為：
            <i>
              太陽、地球、月亮、北交點、南交點、水星、金星、火星、木星、土星、天王星、海王星、冥王星
            </i>
            ，填完後點選<span className="font-medium">送出查詢</span>
            即可看到結果。
            <br />
            <br />
            溫馨提示1：結果可以直接透過網址分享給別人喔！
            <br />
            溫馨提示2：爻辭註解如果出現行星符號，是表示在這個行星啟動特有的解釋。
          </p>
          <div className="modal-action">
            <label htmlFor="how-to-use" className="btn">
              關閉
            </label>
          </div>
        </label>
      </label>
    </>
  );
}

function Result({ result, reset }) {
  const { design, personality } = result;
  return (
    <div className="px-3">
      <div className="flex flex-wrap -mx-3">
        <div className="flex w-full lg:w-1/2 px-3 mb-6">
          <div className="flex-1 card shadow-xl bg-red-100">
            <div className="card-body">
              <h2 className="card-title mb-2.5">設計 Design</h2>
              {PLANET_LABELS.map(({ key, label, symbol }) => (
                <ResultBlock
                  key={`d_${key}`}
                  label={label}
                  symbol={symbol}
                  {...design[key]}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 px-3 mb-6">
          <div className="flex-1 card shadow-xl bg-gray-100">
            <div className="card-body">
              <h2 className="card-title mb-2.5">個性 Personality</h2>
              {PLANET_LABELS.map(({ key, label, symbol }) => (
                <ResultBlock
                  key={`p_${key}`}
                  label={label}
                  symbol={symbol}
                  {...personality[key]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-3">
        <button className="btn px-10 mr-6" onClick={shareResult}>
          分享結果
        </button>
        <button className="btn btn-outline px-10" onClick={reset}>
          重新查詢
        </button>
      </div>
    </div>
  );
}

function App() {
  let [searchParams, setSearchParams] = useSearchParams();

  let result = useMemo(() => {
    let d = searchParams.get("d");
    let p = searchParams.get("p");
    if (!(d && p)) return null;
    d = d.split("|");
    p = p.split("|");
    if (!(d.length === 13 && p.length === 13)) return null;
    let design = {},
      personality = {};
    PLANET_LABELS.forEach(({ key }, idx) => {
      design[key] = d[idx];
      personality[key] = p[idx];
    });

    return {
      design: generateResult(design),
      personality: generateResult(personality),
    };
  }, [searchParams]);

  return (
    <>
      <div className="container mx-auto py-6">
        <div className="flex justify-between items-center mx-3 mb-6">
          <a href="/">
            <h1 className="text-xl">人類圖爻辭速查</h1>
          </a>
          <div>
            <label htmlFor="how-to-use" className="text-sm cursor-pointer mr-3">
              如何使用
            </label>
            <label htmlFor="planet-meaning" className="text-sm cursor-pointer">
              行星代表的意義
            </label>
          </div>
        </div>

        {result ? (
          <Result result={result} reset={() => setSearchParams({})} />
        ) : (
          <Form setSearchParams={setSearchParams} />
        )}
      </div>
      <HowToUse />
      <PlanetMeaning />
    </>
  );
}

export default App;
